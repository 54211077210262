.cookie-policy {
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .page-title {
    margin-bottom: 15px;
  }
}

@primary-color: #009aa6;