@import "./variables.less";

.font-12(@line-height: 15px) {
  font-family: Lato;
  font-size: 12px;
  line-height: @line-height;
}

.font-14(@line-height: 17px) {
  font-family: Lato;
  font-size: 14px;
  line-height: @line-height;
}

.font-18(@line-height: 22px) {
  font-family: Lato;
  font-size: 18px;
  line-height: @line-height;
}

.font-20(@line-height: 24px) {
  font-family: Lato;
  font-size: 20px;
  line-height: @line-height;
}

.font-24(@line-height: 30px) {
  font-family: Lato;
  font-size: 24px;
  line-height: @line-height;
}

.font-30(@line-height: 36px) {
  font-family: Lato;
  font-size: 30px;
  line-height: @line-height;
}

.form-item-label-mixin {
  line-height: 15px;

  label {
    height: 15px;
    width: 37px;
    color: @dp-form-label-color;
    .font-12();
  }
}

.modal-mixin {
  .ant-modal-header {
    height: 110px;
    border-width: 0;

    .ant-modal-title {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      height: 30px;
      color: #353638;
      .font-24();
      font-weight: bold;
      margin-top: 40px;
    }
  }
}
