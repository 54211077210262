.ErrorBoundary {
  .dp-error-blur {
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    opacity: 0.7;
    -webkit-filter: blur(0.5px);
    filter: blur(0.5px);
  }
}

@primary-color: #009aa6;