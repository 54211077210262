.ant-tag.YourEmployerTag {
  color: #fbfcfc;
  font-family: Lato;
  font-size: 9px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  vertical-align: baseline;
  border-radius: 11px;
  height: 19px;
  border: 1px solid #cad7d8;
  background-color: white;
  padding: 0 10px;
}

@primary-color: #009aa6;