.tag {
  height: 34px;
  width: 144px;
  border-radius: 4px;
  text-align: center;
  float: left;
  margin-right: 10px;

  span {
    position: relative;
    top: 6px;
  }
}

.submitted-tag {
  background-color: #ede8fa;

  span {
    color: #7059ba;
  }
}

.in-review-tag {
  background-color: #e6f0fa;

  span {
    color: #38608c;
  }
}

.payment-eligible-tag {
  background-color: #e3fae4;

  span {
    color: #036c07;
  }
}

.payment-ineligible-tag {
  background-color: #fae3e4;

  span {
    color: #9c0c03;
  }
}

.payment-processed-tag {
  background-color: #d6f3ef;

  span {
    color: #195a52;
  }
}

@primary-color: #009aa6;